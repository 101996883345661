  
  table.printTable{
    border-collapse: collapse;
  }

  table.printTable, table.printTable th, table.printTable td{
    border: 1px solid black;
    font-size: 11px;
  }


  .ans{
    color:blue
  }


  td.q{
    text-align: right;
    width:120px
  }
  table.printTable td{
    padding:2px
  }

  table.printTable tr.noWrap td{
    white-space: nowrap;
  }


@media screen{
  .noPrintBorder{
    border: 1px solid black;
  }

  .noPrintPadding{
    padding:20px;
  }

}